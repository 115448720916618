
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate, } from "react-router-dom";
// import SignUp from "../src/Pages/SignUP/SignUp";
import 'react-toastify/dist/ReactToastify.css';
import SkainetAcademyPage from "./Components/Drawer/Drawer";
import SideDrawer from "./Components/Drawer/SideDrawer";
import CourseOverview from "./Pages/CourseOverview/CourseOverview";
import ContentLibrary from "./Pages/ContentLibrary/ContentLibrary";
import ContentLibrary1 from "./Pages/ContentLibrary1/ContentLibrary1";
import LandingPage from "./Pages/LandingPage/LandingPage.js";
import FunnelSecond from "./Components/FunnelSecond/FunnelSecond";
import Funnel from "./Pages/Funnel_page/Funnel.js";
import Twiter_mastry from "./Pages/Twiter_mastery/Twiter_mastry.js";
import AccountVerification from "./Pages/AccountVerification/AccountVerification.js";
import SignInSkainet from "./Pages/SignInSkainet/SignInSkainet.js";
import ResetPassword1 from "./Pages/ResetPassword1/ResetPassword1.js";
import ResetPassword2 from "./Pages/ResetPassword2/ResetPassword2.js";
import ResetVerifyCode from "./Pages/Reset Verify/ResetVerifyCode.js";
import ProtectedRoute from "./utils/Protected Route/ProtectedRoute.js";
import Empty from "./Pages/Empty/Empty.js";
import Empty1 from "./Pages/Empty/Empty1.js";
import Empty2 from "./Pages/Empty/Empty2.js";
import Instagramcompont from "./Components/instagramcompont/Instagramcompont.js";
import Twitter from "./Pages/Twiter-card/Twiter.js";
import AccountNotVerify from "./Pages/AccoutNotVerify/AccountNotVerify.js";
import ProfileView from "./Pages/Profile/ProfileView.js";


function App() {
  const DashboardLayout = () => (
    <>
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <Outlet />
      </SideDrawer>
    </>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<SignInSkainet />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/resetpassword" element={<ResetPassword1 />} />
        <Route path="/newpassword/:email" element={<ResetPassword2 />} />
        <Route path="/resetpasswordverification/:email" element={<ResetVerifyCode />} />
        <Route path="/accountverification/:email" element={<AccountVerification />} />
        <Route path="/accountnotverify/:email" element={<AccountNotVerify />} />
        <Route path="/funnel" element={<Funnel />} />


        <Route element={<DashboardLayout />}>
          <Route path="/courseoverview/:courseId" element={<ProtectedRoute><CourseOverview /></ProtectedRoute>} />
          <Route path="/contentlibrary" element={<ProtectedRoute><ContentLibrary /></ProtectedRoute>} />
          <Route path="/instagramcourses" element={<Instagramcompont />} />
          <Route path="/twittercourses" element={<ProtectedRoute><Twitter /></ProtectedRoute>} />
          <Route path="/twitermastery" element={<ProtectedRoute><Twiter_mastry /></ProtectedRoute>} />
          <Route path="/content-library1/:folder" element={<ProtectedRoute><ContentLibrary1 /></ProtectedRoute>} />
          <Route path="/Empty" element={<ProtectedRoute><Empty /></ProtectedRoute>} />
          <Route path="/Empty1" element={<ProtectedRoute><Empty1 /></ProtectedRoute>} />
          <Route path="/Empty2" element={<ProtectedRoute><Empty2 /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><ProfileView /></ProtectedRoute>} />

        </Route>
      </Routes>
    </Router>
  );
}

export default App;