// import React from "react";
// import { Navigate, useLocation } from "react-router-dom";
// const ProtectedRoute = ({ children, allowedRole }) => {
//   let token = localStorage.getItem("token");
//   let role = localStorage.getItem("role");
//   let location = useLocation();
//   if (!token) {
//     return <Navigate to="/" state={{ from: location }} replace />;
//   } else if (allowedRole && role !== allowedRole) {
//     return <Navigate to="/Dashboard" state={{ from: location }} replace />;
//   }
//   return children;
// };
// export default ProtectedRoute;
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  console.log(token,"token");
  console.log('edgjahhhhhhh');
  console.log('edgjahhhhhhh');
  let location = useLocation();
  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
