import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";




export const getInstagramCourses = createAsyncThunk("getInstagramCourses", async () => {
  try {
    const params = { courseType: "Instagram" }; 
    const token=localStorage.getItem('token');
    console.log("token",token);
    const res = await api.get(`${API_URL}/course/get-courses-website`, {
      params,  
      headers: {
        Authorization: `Bearer ${token}`,  
      }
    });

    return {
      status: res?.status,
      data: res?.data,
      res
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});



