import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInstagramCourses } from "../../services/redux/middleware/getInstagramCourses";
import ScreenLoader from "../ScreenLoader/ScreenLoader";
import { useLocation } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

export default function Instagramcompont() {
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    localStorage.setItem("SelectedOption", "Instagram")
  }, [])

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instagramCoursesData = useSelector(
    (state) => state?.getInstagramCourses?.instagramCoursesData
  );
  console.log(instagramCoursesData, "insta Data")
  console.log("Courses fetched:", instagramCoursesData);
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      console.log("Fetching courses...");

      await dispatch(getInstagramCourses());
      setIsLoading(false);

    };

    fetchCourses();
  }, [dispatch]);

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formatted = `${hours}h ${minutes}min`;
    console.log("Formatted duration:", formatted);
    return formatted;
  };

  const handleCourseClick = (id) => {
    console.log("Navigating to course:", id);

    navigate(`/courseoverview/${id}`);
  };
  const handleButtonClick = () => {
    window.open('https://skainetsystems.com/', '_blank');
  };
  console.log("Instagram courses data:", instagramCoursesData);
  const handleInstaClick = (id) => {
    console.log("id is", id)
    if (location.pathname === "/instagramcourses") {
      localStorage.setItem("navigatedFrom", location.pathname)
      localStorage.setItem("SelectedOption", null)
    }
    navigate(`/courseoverview/${id}`);
  }
  console.log(instagramCoursesData?._id)

  const formatDurationNew = (totalSeconds) => {
    totalSeconds = Math.floor(totalSeconds);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    // Format the duration string
    if (hours > 0) {
      return `${hours}h ${minutes}min`;
    } else if (minutes > 0) {
      return `${minutes}min ${seconds}s`;
    } else {
      return `${seconds}sec`;
    }
  };
  useEffect(() => {


    const token = new URLSearchParams(window.location.search).get("token");
    console.log(token, "jwt token");
    if (token) {
      try {

        const decodedToken = jwtDecode(token);
        console.log(decodedToken, "decoded token");
        setUserId(decodedToken?.id);
        localStorage.setItem('newuserId', decodedToken?.id);
        localStorage.setItem('token', token);
      } catch (error) {
        console.error("Invalid token", error);
      }
    } else {
      console.error("No token found in URL");
    }
  }, []);


  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="Cards___Twiter__alignmnt_Container">
          {instagramCoursesData?.length > 0 ? (
            instagramCoursesData.map((course, index) => (
              <div key={index} className="card_img_container" onClick={() => handleInstaClick(course?._id)}>
                <img
                  className="img-alignmnt"
                  src={course?.thumbnailImage}
                  alt="Course Logo"
                />
                <div className="card_container_twiter">
                  <div className="Twiter_mastry____container">
                    <h2 className="twiter_Text__mastry">

                      {course.title.length > 20 ? course.title.slice(0, 20) + '...' : course.title}
                    </h2>
                    {/* <h2 className="dollor_text">{course.price}</h2> */}
                  </div>
                  <div className="timer_container">
                    <div className="timer__containr">
                      <img
                        src="/Images/DrawerImages/twiter/time.svg"
                        alt="Time"
                      />
                      <p className="time_text">
                        {formatDurationNew(

                          course?.sections?.reduce(
                            (total, section) =>
                              total +
                              section?.duration +
                              section?.subSections?.reduce(
                                (subTotal, subsection) => subTotal + (subsection?.duration || 0),
                                0
                              ),
                            0
                          )


                        )}

                      </p>
                    </div>

                    <div className="timer__containr">
                      <img
                        src="/Images/DrawerImages/twiter/chapter.svg"
                        alt="Lessons"
                      />
                      <p className="time_text">  {course?.sections?.reduce((total, section) =>
                        total + (section?.subSections?.length || 0), 0)
                      } lessons</p>
                    </div>
                    {/* <div className="timer__containr">
                      <img
                        src="/Images/DrawerImages/twiter/star.svg"
                        alt="Rating"
                      />
                      <p className="time_text">{course.averageRating || 0}</p>
                    </div> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="Empty-State">
              <img src="/Images/ContentLibraryImg/emptyd.svg" className="content-Image" alt="" />
              <p className="NoCourse" style={{ padding: "0px" }}>Access Denied</p>
              <p className="empty2ndtext">You don’t have access to this module. Please buy this from<span className="innerrrrrrrrrbottom" onClick={handleButtonClick}> skainetsystems.com</span></p>
              <button className="emptystatebutton" onClick={handleButtonClick}>Buy Course</button>
            </div>
          )}
        </div>
      )}
    </>
  );
}