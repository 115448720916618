import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getProfileData = createAsyncThunk("getProfileData", async (id) => {
  try {   
    const res = await api.get(`${API_URL}/auth/user-information/${id}`);
    console.log("profile:", res);    
      return res;
    
  } catch (error) {
    console.error("Error fetching course:", error);
    return {
      message: error?.response?.data?.error || "Unknown error",
      status: error?.response?.status || 500,
    };
  }
});