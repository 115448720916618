import "./Drawer.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "../Header/Header";
import { toast, ToastContainer } from "react-toastify";
import { getProfileData } from "../../services/redux/middleware/getProfileData";

// import useAuth from "../../hooks/Web3Connection/useAuth";
// import Web3Modal from "../modal/Web3modal/Web3Modal";
import Avatar from "@mui/material/Avatar";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
// import { useWeb3React } from "@web3-react/core";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu, MenuItem, makeStyles } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import { useMediaQuery } from "react-responsive";
import { borderBottom, borderRight, display, minHeight } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "Montserrat",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  padding: "26px 24px",
  borderBottom: "none",
  zIndex: "2",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const themeMui = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});
function getHeaderName(pathname) {
  if (pathname.startsWith("/courseoverview/")) {
    return "Courses";
  }
  if (pathname.startsWith("/content-library1/")) {
    return "Content Library";
  }
  switch (pathname) {
    case "/contentlibrary":
      return "Content Library";
    case "/content-library1":
      return "Content Library";
    case "/twitermastery":
      return "Courses";
    case "/ContentLibrary":
      return "Content Library";
    case "/ContentLibraryCards":
      return "Content Library";
    case "/profile":
      return "Courses";

    case "/instagramcourses":
      return "Courses";
    case "/twittercourses":
      return "Courses";
    default:
      return "";
  }
}


export default function SideDrawer({ children, showSidebar, PageName }) {
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [selectedSubItem, setSelectedSubItem] = useState(1);
  const [active, setactive] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const modalRef = React.useRef(null);



  const handle_accordian = (abc) => {
    setactive(active === abc ? "" : abc);
  };
  const [selectedBorder, setSelectedBorder] = useState(false);
  const handleSubItemClick = (index, text) => {
    console.log("checking", index);
    setSelectedSubItem(index);
    setSelectedBorder(true);
  };
  console.log("dfdsjfhkf", selectedBorder);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    localStorage.setItem("SelectedOption", null)
  }, [])



  const [selectedotherItem, setSelectedOtherItem] = useState(null);

  const handleListItemClick = (index, text) => {
    console.log("clicked", index);
    setSelectedSubItem(null);
    setSelectedOtherItem(null);
    setSelectedItem(index);
    if (window.innerWidth < 1030) {
      setOpen(false);
      setMobileOpen(prevState => !prevState);
    }
    if (text === "Courses") {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setIsDropdownOpen(false);
    }

  };

  const handleOtherListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedItem(null);
    setSelectedOtherItem(index);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    console.log("this is clicked");
    setOpen(true);

  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);

  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    console.log("closing");
  };

  const handleLogout = () => {
    handleClose();
  };
  const handleLogoutMain = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("newuserId");

    navigate('/login');
    setTimeout(() => {
      toast.success("Logout Successfully!");
    }, 500);
  };

  const handleSignIn = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    {
      text: "Courses",
      path: "/instagramcourses",
      disabled: false,
    },
    // { text: "Lead Generator", path: "/Empty1", disabled: false },
    // { text: "Account", path: "/Dashboard/UserProfile", disabled: false },
    { text: "Content Library", path: "/contentlibrary", disabled: false },
    // { text: "SMM Automated", path: "/Empty2", disabled: false },

    // { text: "Partner Program", path: "/Dashboard/Admin", disabled: false },
  ];
  const dashicons = [
    "/Images/Dashboard/dimage.svg",
    "/Images/Dashboard/staking.svg",
    "/Images/Dashboard/Airdrops.svg",
    "/Images/Dashboard/pp.svg",
    // "/Images/Dashboard/wealth.svg",
    // "/Images/Dashboard/latestnews.svg",
    // "/Images/Dashboard/Airdrops.svg",
  ];
  const activeDashicons = [
    "/Images/Dashboard/dimage.svg",
    "/Images/Dashboard/staking.svg",
    "/Images/Dashboard/Airdrops.svg",
    "/Images/Dashboard/pp.svg",
  ];
  const OthermenuItems = [{}, {}];
  const otherdashicons = ["", ""];

  let users = localStorage.getItem("user");

  let filteredMenuItems = [];
  let filteredOtherMenuItems = [];

  if (users?.role === 1) {
    filteredMenuItems = menuItems.filter((item) =>
      ["Home", "File Manager", "Admin"].includes(item.text)
    );
  } else {
    filteredMenuItems = menuItems.filter((item) => item.text !== "Admin");
    filteredOtherMenuItems = OthermenuItems.filter(
      (item) => item.text !== "Admin"
    );
  }

  // for connect wallet
  const [wallet, SetWallet] = useState(false);
  // const { account, chainId } = useWeb3React();
  // const { logout } = useAuth();
  const connectorId =
    typeof window !== "undefined" ? localStorage.getItem("connectorId") : null;
  async function handleConnect() {
    // if (!account) {
    //   SetWallet(true);
    // } else {
    //   await logout(connectorId);
    //   localStorage.clear();
    // }
  }


  useEffect(() => {
    // Function to check if the screen is mobile-sized
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (open && isMobile) {
      document.body.style.overflow = "hidden"; // Disable body scroll
    } else {
      document.body.style.overflow = "auto"; // Enable body scroll
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  useEffect(() => {

    const pathToActive = {
      '/Empty': 0,
      '/Empty1': 1,
      "/contentlibrary": 2,
      "/Empty2": 3,
      '/instagramcourses': 0,
      '/twittercourses': 0,
    };

    setSelectedItem(pathToActive[location.pathname]);
    console.log("selected", selectedItem);

  }, []);

  useEffect(() => {
    const pathToActive = {
      '/instagramcourses': 1,
      '/twittercourses': 2,
    };

    setSelectedSubItem(pathToActive[location.pathname] || '');
  }, [location.pathname, handleListItemClick]);


  const closeDrawerOnOutsideClick = (event) => {
    // Close the drawer if the click is outside the Drawer area
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setMobileOpen(false);
    }
  };

  useEffect(() => {
    if (mobileOpen) {
      document.addEventListener("mousedown", closeDrawerOnOutsideClick);
    } else {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    };
  }, [mobileOpen]);


  const dispatch = useDispatch();
  const detailData = useSelector((state) => state?.getProfileData);
  const newuserId = localStorage.getItem("newuserId");
  console.log(newuserId,"newysesidedrawer");
  // const id =JSON.parse(newuserId);
  // console.log(id,"idedrawer");
  useEffect(() => {
    dispatch(getProfileData(newuserId)).then((res) => {
      console.log("profile data", res)
    });
  }, [dispatch,newuserId ]);

  const [profileImage, setProfileImage] = useState();


  useEffect(() => {
    console.log("Detail for Profile:", detailData?.getProfileData?.data)
    if (detailData?.getProfileData?.data) {
      const data = detailData.getProfileData.data;
      setProfileImage(data.profileImage);
    }
  }, [detailData?.getProfileData?.data]);

  const isIOSMobile = () => {
    const userAgent = navigator.userAgent;
    return /iPhone|iPod/.test(userAgent) && !window.MSStream;
  };

  // Set padding conditionally
  const paddingValue = isIOSMobile() ? '120px' : '20px';


  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box
            sx={{
              paddingLeft: open
                ? isMobile
                  ? "0px" // Padding when the drawer is open on mobile
                  : `${drawerWidth}px` // Padding when the drawer is open on larger screens
                : isMobile
                  ? "0px" // Padding when the drawer is closed on mobile
                  : "28px", // Padding when the drawer is closed on larger screens
              transition: "padding-left 0.3s ease", // Smooth transition when opening/closing drawer
            }}
          >
            <CssBaseline />
            <AppBar
              position="fixed"
              open={open}
              style={{
                zIndex: "10",
                padding: "12px 32px",
                boxShadow: "none",
                borderBottom: "1px solid #24262b",
                backgroundColor: "#111214",
              }}
              className="sidebar__top-main"
            >
              <Toolbar
                style={{
                  display: "flex",
                  position: "relative",
                  zIndex: "2",
                  padding: "0px",
                  minHeight: "59px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    color: "#FFFFFF",
                    zIndex: 999,
                  }}
                >
                  <MenuIcon
                    style={{
                      position: "relative",
                      zIndex: 999,
                    }}
                    className="toggle"
                    color={"black"}
                  />
                </IconButton>

                <div style={{ padding: "0px" }}>
                  <AvatarTopDiv
                    style={{ zIndex: 1, width: "100%", right: "0" }}
                  >
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div>
                        <p>
                          <Header
                            headername={getHeaderName(location.pathname)}
                          />
                        </p>
                      </div>
                      <div className="notify-search">
                        {/* <button className="topbutton">Connect Wallet</button> */}

                        {/* <img src="/Images/Dashboard/notify.svg" alt="/" /> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                          onClick={() => navigate('/profile')}
                        >
                          {/* <Avatar

                            alt="Profile Picture"
                            src="/Images/Dashboard/Profile.svg"
                            sx={{ width: 40.5, height: 40.5 }}
                            className="avatar-img"
                          /> */}
                          <img src={profileImage ? profileImage : '/Images/Dashboard/Profile.svg'} className={profileImage ? 'Navbar-Profile' : ''} alt="" />
                        </div>
                      </div>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#111214",
                    borderRight: "1px solid #24262b",
                    display: 'flex',
                    justifyContent: 'space-between'
                  },
                }}
                style={{ zIndex: 1, position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
                BackdropProps={{
                  invisible: true, // Make backdrop invisible but still clickable
                }}
              >
                <div className="drawer__main__container1" >
                  <div>
                    <DrawerHeader>
                    <a href="https://skainet.academy/">
                      <img
                        src="/Images/Dashboard/logo.svg"
                        // width={"30px"}
                        // height={"30px"}
                        className="logo__container1"
                        alt="Logo"
                      /></a>
                      <IconButton
                        onClick={handleDrawerClose}
                        style={{ color: "#000" }}
                      >
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon style={{ color: "#FFFFFF" }} />
                        ) : (
                          <ChevronRightIcon style={{ color: "#FFFFFF" }} />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    {/* <Divider /> */}
                    <List className="List-div">
                      {/* <p
                      className="items-main-heading"
                      style={{ paddingTop: "32px" }}
                    >
                      General Menu
                    </p> */}
                      {filteredMenuItems?.map(
                        ({ text, path, disabled }, index) => (
                          <React.Fragment key={index}>
                            <ListItem
                              selected={index === selectedItem}
                              disabled={disabled}
                              disablePadding
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "22.4px",
                              }}
                            >
                              <ListItemButton
                                component={Link}
                                to={path}
                                onClick={() => handleListItemClick(index, text)}
                                selected={index === selectedItem || (text === "Courses" &&
                                  (isDropdownOpen || [1, 2].includes(selectedSubItem)))}
                                sx={{
                                  background:
                                    index === selectedItem || (text === "Courses" &&
                                      (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                      ? "#393C43 !important"
                                      : "transparent",
                                  color:
                                    index === selectedItem || (text === "Courses" &&
                                      (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                      ? "#FFFFFF !important"
                                      : "#FFFFFF",
                                  border:
                                    index === selectedItem || (text === "Courses" &&
                                      (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                      ? "1px solid #0F6FFF !important"
                                      : "none",
                                  "&:hover": {

                                    color: "#FFFFFF ",
                                  },
                                }}
                              >
                                <ListItemIcon
                                  style={{minWidth:"33"}}
                                  sx={{
                                    "& ..MuiListItemIcon-root": {
                                      minWidth: "24px",
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      index === selectedItem
                                        ? activeDashicons[index]
                                        : dashicons[index]
                                    }
                                    alt={`Icon ${index + 1}`}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                              </ListItemButton>
                            </ListItem>

                            {/* CoursesDropdown */}
                            {text === "Courses" && isDropdownOpen && (
                              <div
                                style={{ paddingLeft: "30px", marginTop: "5px" }}
                              >
                                <ListItemButton
                                  component={Link}
                                  to="/instagramcourses"
                                  state={{
                                    from: window.location.pathname
                                  }}
                                  onClick={() => {
                                    handleSubItemClick(1);
                                    if (window.innerWidth < 1030) {
                                      setOpen(false);
                                      setMobileOpen(prevState => !prevState);
                                    }
                                    setSelectedItem(
                                      filteredMenuItems.findIndex(
                                        (item) => item.text === "Courses"
                                      )
                                    );
                                  }}
                                  sx={{
                                    color:
                                      (localStorage.getItem("SelectedOption") === "Instagram" || selectedSubItem === 1)
                                        ? "#0F6FFF !important"
                                        : "#FFFFFF",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                    "&:hover": {
                                      color: "#FFFFFF ",

                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      localStorage.getItem("SelectedOption") === "Instagram" || selectedSubItem === 1
                                        ? "/Images/Dashboard/subinstablue.svg"
                                        : "/Images/Dashboard/subIconInstagram.svg"
                                    }
                                    alt="Instagram Icon"
                                  />
                                  <ListItemText primary="Instagram Course" />
                                </ListItemButton>
                                <ListItemButton
                                  component={Link}
                                  to="/twittercourses"
                                  state={{
                                    from: window.location.pathname
                                  }}
                                  onClick={() => {
                                    handleSubItemClick(2);
                                    if (window.innerWidth < 1030) {
                                      setOpen(false);
                                      setMobileOpen(prevState => !prevState);
                                    }
                                    setSelectedItem(
                                      filteredMenuItems.findIndex(
                                        (item) => item.text === "Courses"
                                      )
                                    );
                                  }}
                                  sx={{
                                    color:
                                      (localStorage.getItem("SelectedOption") === "Twitter" || selectedSubItem === 2)
                                        ? "#0F6FFF !important"
                                        : "#FFFFFF",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                    "&:hover": {
                                      color: "#FFFFFF",

                                    },

                                  }}
                                >
                                  <img
                                    src={
                                      localStorage.getItem("SelectedOption") === "Twitter" || selectedSubItem === 2
                                        ? "/Images/Dashboard/twittersub.svg"
                                        : "/Images/Dashboard/subIconTwitter.svg"
                                    }
                                    alt="Twitter Icon"
                                  />
                                  <ListItemText primary="X (Twitter Course)" />
                                </ListItemButton>
                              </div>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </List>

                  </div>
                  <div
                    style={{ cursor: "pointer",}}
                    onClick={() => handleLogoutMain()}
                    className="Logout-div"
                    
                  >
                    <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                    <p className="Logout-txt">Logout</p>
                  </div>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <Drawer
                ref={modalRef}
                PaperProps={{
                  sx: {
                    backgroundColor: "#101214",
                  },
                }}
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={mobileOpen}
                onClose={() => handleDrawerToggle}

              >
                <div>
                  <DrawerHeader>
                    <img
                      src="/Images/Dashboard/logo.svg"
                      // width={"30px"}
                      // height={"30px"}
                      className="ms-3"
                      alt="Logo"
                    />
                    <IconButton
                      onClick={handleDrawerClose}
                      style={{ color: "#000" }}
                    >
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon style={{ color: "#FFFFFF" }} />
                      ) : (
                        <ChevronRightIcon style={{ color: "#FFFFFF" }} />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  {/* <Divider /> */}
                  <List className="List-div">

                    {filteredMenuItems?.map(
                      ({ text, path, disabled }, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            selected={index === selectedItem}
                            disabled={disabled}
                            disablePadding
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "22.4px",
                            }}
                          >
                            <ListItemButton
                              component={Link}
                              to={path}
                              onClick={() => handleListItemClick(index, text)}
                              selected={index === selectedItem || (text === "Courses" &&
                                (isDropdownOpen || [1, 2].includes(selectedSubItem)))}
                              sx={{
                                background:
                                  index === selectedItem || (text === "Courses" &&
                                    (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                    ? "#393C43 !important"
                                    : "transparent",
                                color:
                                  index === selectedItem || (text === "Courses" &&
                                    (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                    ? "#FFFFFF !important"
                                    : "#FFFFFF",
                                border:
                                  index === selectedItem || (text === "Courses" &&
                                    (isDropdownOpen || [1, 2].includes(selectedSubItem)))
                                    ? "1px solid #0F6FFF !important"
                                    : "none",
                                "&:hover": {

                                  color: "#FFFFFF ",

                                },

                              }}
                            >
                              <ListItemIcon
                                className="kuchdaydo"
                                sx={{
                                  "& ..MuiListItemIcon-root": {
                                    minWidth: "24px",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    index === selectedItem
                                      ? activeDashicons[index]
                                      : dashicons[index]
                                  }
                                  alt={`Icon ${index + 1}`}
                                />
                              </ListItemIcon>
                              <ListItemText primary={text} />
                            </ListItemButton>
                          </ListItem>

                          {/* CoursesDropdown */}
                          {text === "Courses" && isDropdownOpen && (
                            <div
                              style={{ paddingLeft: "30px", marginTop: "5px" }}
                            >
                              <ListItemButton
                                component={Link}
                                to="/instagramcourses"
                                state={{
                                  from: window.location.pathname
                                }}
                                onClick={() => {
                                  handleSubItemClick(1);
                                  if (window.innerWidth < 1030) {
                                    setOpen(false);
                                    setMobileOpen(prevState => !prevState);
                                  }
                                  setSelectedItem(
                                    filteredMenuItems.findIndex(
                                      (item) => item.text === "Courses"
                                    )
                                  );
                                }}
                                sx={{
                                  color:
                                    localStorage.getItem("SelectedOption") === "Instagram" || selectedSubItem === 1
                                      ? "#0F6FFF !important"
                                      : "#FFFFFF",
                                  display: "flex",
                                  gap: "12px",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#FFFFFF ",

                                  },
                                }}
                              >
                                <img
                                  src={
                                    localStorage.getItem("SelectedOption") === "Instagram" || selectedSubItem === 1
                                      ? "/Images/Dashboard/subinstablue.svg"
                                      : "/Images/Dashboard/subIconInstagram.svg"
                                  }
                                  alt="Instagram Icon"
                                />
                                <ListItemText primary="Instagram Course" />
                              </ListItemButton>
                              <ListItemButton
                                component={Link}
                                to="/twittercourses"
                                state={{
                                  from: window.location.pathname
                                }}
                                onClick={() => {
                                  handleSubItemClick(2);
                                  if (window.innerWidth < 1030) {
                                    setOpen(false);
                                    setMobileOpen(prevState => !prevState);
                                  }
                                  setSelectedItem(
                                    filteredMenuItems.findIndex(
                                      (item) => item.text === "Courses"
                                    )
                                  );
                                }}
                                sx={{
                                  color:
                                    localStorage.getItem("SelectedOption") === "Twitter" || selectedSubItem === 2
                                      ? "#0F6FFF !important"
                                      : "#FFFFFF",
                                  display: "flex",
                                  gap: "12px",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#FFFFFF",

                                  },
                                }}
                              >
                                <img
                                  src={
                                    localStorage.getItem("SelectedOption") === "Twitter" || selectedSubItem === 2
                                      ? "/Images/Dashboard/twittersub.svg"
                                      : "/Images/Dashboard/subIconTwitter.svg"
                                  }
                                  alt="Twitter Icon"
                                />
                                <ListItemText primary="X (Twitter Course)" />
                              </ListItemButton>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </List>
                </div>
                <div
                  style={{ cursor: "pointer",paddingBottom:paddingValue  }}
                  onClick={() => handleLogoutMain()}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">Logout</p>
                </div>
              </Drawer>
            )}

            <Main
              open={open}
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
                height: "100vh",
                scrollbarWidth: "none", // For Firefox
                "&::-webkit-scrollbar": {
                  display: "none", // For Chrome, Safari, and Edge
                },
              }}
              style={{
                msOverflowStyle: "none", // For Internet Explorer and Edge
              }}
            >
              <DrawerHeader />
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        progressStyle={{ background: '#0F6FFF' }}
      />
    </>
  );
}