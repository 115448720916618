import React from "react";
import "./NewFooter.css";
import Logo from "../../assets/logo.svg";
import FacebookIcon from "../../assets/facebook.svg";
import TwitterIcon from "../../assets/twitter.svg";
import InstagramIcon from "../../assets/instagram.svg";
import TelegramIcon from "../../assets/telegram.svg";

const NewFooter = () => {
  return (
    <div className="Footer">
      <div className="Footer1">
        <div className="Footer2">
          <div className="LogoFooter">
            <img className="logo" src={Logo} alt="Logo" />
          </div>
          <p className="copyRight">Copyright 2025 © Skainet Academy</p>
          <div className="social-links">
            <a
              href="https://www.instagram.com/skainet_systems"
              className="social-link"
            >
              Insta
            </a>
            <span className="separator">·</span>
            <a
              href="https://www.facebook.com/profile.php?id=100063529182444&__tn__=-UC*F"
              className="social-link"
            >
              Facebook
            </a>
            <span className="separator">·</span>
            <a href="https://twitter.com/SkainetAI" className="social-link">
              Twitter
            </a>
            <span className="separator">·</span>
            <a href="https://t.me/skainetsystems" className="social-link">
              Telegram
            </a>
          </div>
          <p className="copy__right__text">
          Copyright 2024 © Skainet Academy
          </p>
          <div className="social__links__icons">
            <a
              href="https://www.instagram.com/skainet_systems"
              className="social-link"
            >
              <img className="icon" src={InstagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100063529182444&__tn__=-UC*F"
              className="social-link"
            >
              <img className="icon" src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://twitter.com/SkainetAI" className="social-link">
              <img className="icon" src={TwitterIcon} alt="Twitter" />
            </a>
            <a href="https://t.me/skainetsystems" className="social-link">
              <img className="icon" src={TelegramIcon} alt="Telegram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
