import React, { useEffect, useState } from "react";
import './SignInSkainet.css'
import { ToastContainer, toast } from 'react-toastify';
import { login } from "../../services/redux/middleware/login";
import { auth } from "../../services/redux/middleware/auth";
import { forgetPassword } from "../../services/redux/middleware/forgetPassword";
import '../../Pages/SignUP/SignUp.css'
import { useDispatch } from "react-redux";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useNavigate } from "react-router-dom";
const SignInSkainet = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();



  const [isbuttonLoading, setIsButtonLoading] = useState(false);
  const [ip, setIpAddress] = useState("");
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("Error fetching IP address:", error));
  }, []);

  const handleLogin = async () => {
    if (!email) {
      toast.error("Please Enter Email");
      return;
    }

    // if (email !== email.toLowerCase()) {
    //   toast.error("Please enter a email address in lowercase");
    //   return;
    // }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsButtonLoading(true);

    const data = { email }
    console.log(data);


    try {
      await dispatch(auth(data)).then((res) => {
        console.log(res, "authh response");


        console.log(data);
        console.log("new resiult", res);
        if (res?.payload?.status === 200) {
          console.log(res?.payload?.data?.userId,"iddddddddd");
          // localStorage.setItem('newuserId',res?.payload?.data?.userId);
          // localStorage.setItem('token',res?.payload?.data?.token);
          setIsButtonLoading(false);
          // navigate('/instagramcourses');
          
          setTimeout(() => {
            toast.success("Verification Link sent to your email");
          }, 300);

        }
        else {
          setIsButtonLoading(false);
          setTimeout(() => {
            toast.error("Something Went Wrong");
          }, 100)
        }
      });
    } catch (error) {
      setIsButtonLoading(false);
    }
  }
  const handleEmailChange = (e) => {
    const input = e.target.value;
    // Convert the first letter to lowercase
    const updatedEmail = input.charAt(0).toLowerCase() + input.slice(1);
    setEmail(updatedEmail);
  };
  const handleButtonClick = () => {
    window.open('https://skainetsystems.com/', '_blank');
  };
  return (

    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="SignUpMainNext">
          <img className="SignUpImgNext" src="/Images/SignupImg/hero.png" alt=""  />
          <div className="SignUpMain11Next">
            <div className="SignUpMain1Next">
              <div className="SignUpMain1_1Next">
                <img className="SignUpMain1_1iconNext" src="/Images/SignupImg/icon.svg" alt="...." />
                <p className="SignUpMain1_1ptagNext">Welcome Back!</p>
              </div>

              <div className="SignUpMain1_2Next">

                <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Please enter your Email Address</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/SignupImg/email.svg" alt="...." className="image-icon" />
                    <input
                      type="text"
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Mail"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
                <p className="alreadysignupnew">You will be sent a one-time verification link to your email. Click that link to access Skainet Academy.</p>
                {/* <div className="SignUpMain1_2_first">
                  <p className="SignUpMain1_2_first_P">Password</p>
                  <div className="SignUpMain1_2_first_Div">
                    <img src="/Images/Siginskainet/pasword.svg" alt="...." className="image-icon" />
                    <input
                      type={showPassword ? "text" : "password"}
                      className="SignUpMain1_2_first_Div_T"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="togglePasswordBtnNext"
                    >
                      {showPassword ? (
                        <img src="/Images/Siginskainet/show.svg" alt="Hide Password" />
                      ) : (
                        <img src="/Images/Siginskainet/hide.svg" alt="Show Password" />
                      )}
                    </button>
                  </div>
                </div> */}
              </div>
              {/* <p className="Forgot-Password" onClick={() => { navigate('/resetpassword') }}>Forgot Password?</p> */}


              <button style={{ marginBottom: "17px", marginTop: "60px " }} className="SignUpMain1_2Btn" onClick={handleLogin}>
                {isbuttonLoading ? (
                  <div className="loader">

                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <p>Sign In</p>
                )}
              </button>



              <p className="alreadysignup">Don’t have an account already?<span className="alreadysignup2" onClick={handleButtonClick} > Sign up to Skainet Systems</span></p>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{ background: '#0F6FFF' }}
          />

        </div>)}</>
  );
};

export default SignInSkainet;
