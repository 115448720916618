import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data) => {
    try {

      const newuserId = localStorage.getItem("newuserId");
      const id = newuserId;

      console.log(newuserId, "id of update")
      console.log(data, "data of update")
      const res = await api.put(`${API_URL}/auth/update-informations/${newuserId}`, data);
      console.log("res", res)
      return {
        status: res?.status,
        data: res?.data?.data,
        token: res?.data?.token,
        res
      };
    } catch (error) {
      return {
        message: error,
      };
    }
  }
);


export const newupdate = createAsyncThunk("newupdate", async (data) => {
  try {
    console.log("auth....",data);
    const newuserId = localStorage.getItem("newuserId");
    console.log(newuserId,"newiddddddddddd")
    const res = await api.put(`${API_URL}/auth/update-informations/${newuserId}`, data);
    return {
      status: res?.status,
      data: res?.data,
    //   token: res?.data?.token,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});